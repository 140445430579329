import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import LogIn from '../views/LogIn.vue'
import Contact from '../views/Contact.vue'
import Documents from '../views/Documents.vue'
import Videos from '../views/Videos.vue'
import PartnerContacts from '../views/partner/Contacts.vue'
import PartnerCompanies from '../views/partner/Companies.vue'
import PartnerDeals from '../views/partner/Deals.vue'
import Stats from '../views/partner/Stats.vue'
import AdminSettings from '../views/admin/Settings.vue'
import AdminDealSettings from '../views/admin/DealSettings.vue'
import AdminContactSettings from '../views/admin/ContactSettings.vue'
import AdminCompanySettings from '../views/admin/CompanySettings.vue'
import AdminDeals from '../views/admin/Deals.vue'
import AdminPartners from '../views/admin/PartnerObjects.vue'
import AdminUsers from '../views/admin/Partners.vue'
import AdminModule from '../views/admin/Module.vue'
import Payment from '../views/admin/Payment.vue'
import MartusGame from '../views/MartusGame.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/martus-game',
    name: 'MartusGame',
    component: MartusGame
  },
  {
    path: '/login',
    name: 'Log In',
    component: LogIn
  },
  {
    path: '/deals',
    name: 'Deals',
    component: PartnerDeals
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: PartnerContacts
  },
  {
    path: '/companies',
    name: 'Companies',
    component: PartnerCompanies
  },
  {
    path: '/contact',
    name: 'Partner Contact',
    component: Contact
  },
  {
    path: '/admin/settings',
    name: 'Settings',
    component: AdminSettings
  },
  {
    path: '/admin/module',
    name: 'Module',
    component: AdminModule
  },
  {
    path: '/admin/deal-settings',
    name: 'Deal Settings',
    component: AdminDealSettings
  },
  {
    path: '/admin/contact-settings',
    name: 'Contact Settings',
    component: AdminContactSettings
  },
  {
    path: '/admin/company-settings',
    name: 'Company Settings',
    component: AdminCompanySettings
  },
  {
    path: '/admin/deals',
    name: 'Deals',
    component: AdminDeals
  },
  {
    path: '/admin/partners',
    name: 'Partners',
    component: AdminPartners
  },
  {
    path: '/admin/users',
    name: 'Users',
    component: AdminUsers
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/admin/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
