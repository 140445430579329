

























































import Vue from 'vue';

export default Vue.extend({
    name: 'MartusGame',
    components: {
    },
    data() {
        return {
            url: ''
        }
    },
    async created() {
        console.log(this.$store.state.user);
        this.url = `https://app.incentivepilot.com/manobyte/809e1661-41ac-4936-8bb3-98b1c300745a/${btoa(this.$store.state.user.name)}/${btoa(this.$store.state.user.email)}`;
        this.$store.state.routeLoaded = true;
    },
})
