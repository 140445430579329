























































































































































































import Vue from 'vue';

export default Vue.extend({
    name: 'Home',
    created() {
        this.$store.state.routeLoaded = true;
    }
})
